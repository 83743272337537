export var version = {
  "@ant-design/pro-card": "2.9.0",
  "@ant-design/pro-components": "2.8.0",
  "@ant-design/pro-descriptions": "2.6.0",
  "@ant-design/pro-field": "2.17.0",
  "@ant-design/pro-form": "2.31.0",
  "@ant-design/pro-layout": "7.21.0",
  "@ant-design/pro-list": "2.6.0",
  "@ant-design/pro-provider": "2.15.0",
  "@ant-design/pro-skeleton": "2.2.0",
  "@ant-design/pro-table": "3.18.0",
  "@ant-design/pro-utils": "2.16.0"
};